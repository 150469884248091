.App {
  margin-top: 4em;
  text-align: center;
}

.App-logo {
  pointer-events: none;
  transform: rotate(90deg);
  width: 100%;
  height: auto;
}
